export const formHsUrl = (id, type) => {
  const baseUrl = 'https://app.hubspot.com/contacts/9053265/record/'
  let t;
  switch (type) {
    case "Contact":
        t='0-1'
        break;
    case "Tiketti":
        t = '0-5' 
        break;
    case "Vakuutus":
        t = '2-11962052'
        break;
    case "Diili":
        t = '0-3' 
        break;
    case "Reklamaatio":
        t = '2-11545401'
        break;
    case "Company":
        t = '0-2' 
        break;
    case "Pyörä":
        t = '2-5361578'
        break;
    case "Muutosilmoitus":
        t = '2-11931549'
        break;
    case "Huolto":
        t = '2-5361603'
        break;
    default:
        t = null;
        break;
  }

  if(!t){return null}

  return baseUrl + t + '/' + id
}

