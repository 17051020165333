import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { createTask } from "./services/make";
import { useLocation} from 'react-router-dom';
import {formHsUrl} from './services/functions'

const App = () => {
  const [taskName, setTaskName] = useState('')
  const [taskDescription, setTaskDescription] = useState('')
  const [taskDate, setTaskDate] = useState()
  const [taskPriority, setTaskPriority] = useState('Medium')
  const [taskTeam, setTaskTeam] = useState('6f19c6ca7d1b426b946b9e18d56c2a6d')
  const [taskTags, setTaskTags] = useState('')
  const [hsObject, setHsObject] = useState('Contact')
  const [hsObjectId, setHsObjectId] = useState('')
  const [includeHubspotObject, setIncludeHubspotObject] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const location = useLocation()

  const curDate = dayjs(`${dayjs().year()}-${dayjs().month()+1}-${dayjs().date()}`).format('YYYY-MM-DD')

  const submit = async(event) => {
    event.preventDefault()
    const body = {
      "name": taskName,
      "summary": taskDescription,
      "due-date": taskDate,
      "priority": taskPriority,
      "team": taskTeam,
      "tags": taskTags,
      "email": '',
      "useremail": email
    }

    if(includeHubspotObject){
      body['hubspot-object'] = hsObject
      body['hubspot-object-email'] = formHsUrl(hsObjectId, hsObject)
      body['hubspot-object-id'] = hsObjectId
    }

    if(taskName === '' || taskDate === ''){
      setErrorMessage('Please fill in all required fields')
      setIsError(true)
      setTimeout(() => {
        setIsError(false);
      }, 3000);
      return
    }
    setLoading(true)
    const res = await createTask(body)
    setLoading(false)

    if(res){
      setIsSuccess(true)
      //console.log('success')
      setTaskName('')
      setTaskDescription('')
      setTaskPriority('Medium')
      setTaskTeam('6f19c6ca7d1b426b946b9e18d56c2a6d')
      setTaskTags('')
      setHsObject('Contact')
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } else{
      console.log('fail')
      setErrorMessage('Task creation failed. Please try again.')
      setIsError(true)
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }

  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const emaile = queryParams.get('useremail')
    console.log(emaile)
    //const type = queryParams.get('type')
    setHsObjectId(id)
    setEmail(emaile)
    //setHsObject(type)
  },[location])

  return (
    <div className="h-screen bg-light-orange p-2 font-light">
      <div className="bg-white rounded shadow p-1 relative">
        {isSuccess && (
          <div className="flex justify-center">
            <div className="absolute top-2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded z-10 w-5/6 text-center gap-2" role="alert">
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline"> Task has been created successfully.</span>
            </div>
          </div>
        )}
        {isError && (
          <div className="flex justify-center">
            <div className="absolute top-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded z-10" role="alert">
              <strong className="font-bold">Error!</strong>
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          </div>
        )}

        <div className="text-xl font-bold p-2 text-center">
          New Notion Task
        </div>
        <form className="p-2">
          <div className="flex justify-center">
            <input type='text' className="p-1 outline-none  rounded-md text-md w-5/6 border border-dark-orange" placeholder="Task Title" onChange={(event) => {setTaskName(event.target.value)}} value={taskName}></input>
          </div>
          <div className="pt-2 h-44 justify-center flex">
            <textarea className="p-1 rounded-md text-sm w-5/6 outline-none h-full border border-dark-orange" placeholder="Task Description" onChange={(event) => {setTaskDescription(event.target.value)}} value={taskDescription}></textarea>
          </div>
          <div className="pb-1">
            <div className="flex w-full py-2 justify-center">
              <div className="flex w-5/12">
                <span className="font-bold text-xs pl-3 w-1/3 pt-2">Date:</span>
                <input type="date" className="text-sm  focus:outline-none border border-dark-orange rounded p-1 w-2/3" 
                onChange={(event) => setTaskDate(event.target.value)} min={curDate}></input>
              </div>
              <div className="flex w-5/12">
                <span className="font-bold text-xs pl-3 w-1/3 pt-2">Priority:</span>
                <select className="text-sm focus:outline-none border border-dark-orange rounded p-1 w-2/3" 
                onChange={(event) => setTaskPriority(event.target.value)}  
                value={taskPriority}>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
              
            </div>
            <div className="flex w-full justify-center">
              <div className="flex w-5/12">
                <span className="font-bold text-xs pl-3 w-1/3 pt-2">Team:</span>
                <select className="text-sm focus:outline-none border border-dark-orange rounded p-1 w-2/3"
                onChange={(event) => setTaskTeam(event.target.value)} 
                value={taskTeam}>
                  <option value="6f19c6ca7d1b426b946b9e18d56c2a6d">Palvelukokemus ja operaatiot</option>
                  <option value="b370ad1bcc084817a558496156a692c6">Kaupalliset toiminnot</option>
                  <option value="5ac27cb8a6cf4ac0acac7872c84bcc84">Johtis</option>
                  <option value="caf1103b0a4c49e8a809bcdd500dcf66">Talous</option>
                  <option value="bd804c70c8024619918957b1b05a942d">Teknologia</option>
                  <option value="8557e8b3e15e4da4b9a801820397e158">GoByBike</option>
                </select>
              </div>
              <div className="flex w-5/12">
                <span className="font-bold text-xs pl-3 w-1/3 pt-2">Tags:</span>  
                <input type="text" className="text-sm focus:outline-none border border-dark-orange rounded p-1 px-2 w-2/3"></input>
              </div>
            </div>
          </div>
            <div className="flex w-full justify-center py-2">
              <div className="flex w-5/12">
                <span className="font-bold text-xs pl-3 w-2/3 pt-2">Include Hubspot Object:</span>
                <input type="checkbox" className=" accent-dark-orange dark:text-white dark:bg-white" onChange={()=> setIncludeHubspotObject(!includeHubspotObject)}></input>
              </div>
              {includeHubspotObject ? (
                <div className="flex w-5/12">
                  <span className="font-bold text-xs pl-3 w-1/3 pt-2">Object:</span>
                  <select className="text-sm focus:outline-none border border-dark-orange rounded p-1 w-2/3"
                  onChange={(event) => setHsObject(event.target.value)} value={hsObject}>
                      <option value="Contact">Contact</option>
                      <option value="Pyörä">Pyörä</option>
                      <option value="Tiketti">Tiketti</option>
                      <option value="Diili">Diili</option>
                      <option value="Huolto">Huolto</option>
                      <option value="Reklamaatio">Reklamaatio</option>
                      <option value="Muutosilmoitus">Muutosilmoitus</option>
                      <option value="Company">Company</option>
                      <option value="Vakuutus">Vakuutus</option>
                    </select>
                </div>
              ) : <div className="w-5/12 h-[28.5px]"></div>}
          </div>
          <div className="flex w-full justify-center">
            <div className="w-5/12">
            </div>
            <div className="w-5/12 flex justify-end">
              <button type="submit" className={`py-1.5 px-2 bg-dark-orange rounded text-white text-sm  ${loading ? '' : 'hover:bg-hover-orange'} `} onClick={submit}
              disabled={loading}>
                <div></div>
                {loading ? 
                  <div className="gap-2 flex">
                    <div className="pt-0.5">
                      <div className="animate-spin inline-block w-4 h-4 border-[2px] border-white border-t-transparent rounded-full" role="status" aria-label="loading"/> 
                    </div>
                    <span className="pl-0.5">Loading...</span>
                  </div>:
                'Create Task'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
