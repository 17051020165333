import axios from 'axios';

const baseUrl = 'https://hook.eu1.make.com/r6ilfekrcmv9n4g619y7llzbve334ggz';

export const createTask = async (data) => {
  try{
    const response = await axios.post(baseUrl, data);
    return response.data;
  } catch (err) {
    return null;
  }
}